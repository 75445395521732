import {consts} from "../constants";
import {request} from "../api/request";
import {
    _urlContact,
    _urlPartner,
    _urlLanguage,
    _urlMenu,
    _urlSlider,
    _urlCustomPage,
    _urlVacancies,
    _urlReview,
    _urlAbout, _urlSearch,
} from "../api/url";
import {_urlStatic} from "../../../admin/redux/api/urls";


//=================================LANGUAGES========================================================
export const GetLanguages = () => {
    return dispatch => {
        return request(_urlLanguage, "GET", false)
            .then(res => {
                dispatch({
                    type: consts.SET_ALL_LANGUAGE_WEB,
                    payload: res.data
                })
            })
            .catch(() => {
            })
    }
};

export const GetTranslations = () => {
    return dispatch => {
        return request(`${_urlStatic}`, "GET", false)
            .then(res => {
                dispatch({
                    type: consts.SET_TRANSLATIONS,
                    payload: res.data
                });
                return res.data
            })
    }
};

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: consts.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

//=================================MENU && SLIDER===================================================

export const GetMenuWeb = () => {
    let url = `${_urlMenu}`;

    return dispatch => {
        return request(url, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_MENU,
                    payload: res.data
                })
            })
            .catch((error) => {
            })
    }
};

export const GetSliderWeb = () => {
    return dispatch => {
        return request(_urlSlider, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_SLIDER,
                    payload: res.data
                })
            })
            .catch((error) => {

            })
    }
};

//=================================REVIEW=========================================================

export const GetReviewWeb = () => {
    return dispatch => {
        return request(_urlReview, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_REVIEW_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {
            })
    }
};

//=================================PARTNERS=========================================================
export const GetPartnerWeb = () => {
    return dispatch => {
        return request(_urlPartner, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_ALL_PARTNERS_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {
            })
    }
};

export const GetPartnerById = id => {
    let url = `${_urlPartner}/${id}`;
    return dispatch => {
        return request(url, "GET", true)
            .then(async res => {
                await dispatch({
                    type: consts.SET_PARTNER_BY_ID,
                    payload: res.data
                })
            }).catch(()=>{

            })
    }
};

export const ClearPartnerDetails = () => {
    return dispatch => {
        dispatch({
            type: consts.CLEAR_PARTNER_DETAILS,

        })
    }
};


//=================================SEARCH===========================================================


export const GetSearchedData = ({searchText = '',} = {}) => {

    let url = `${_urlSearch}`;
    if (searchText) url += `?&text=${searchText}`;

    return dispatch => {
        return request(url, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_SEARCH_DATA,
                    payload: res.data,
                })
            })
            .catch((error) => {
                // swal({
                //     title: "Oops!",
                //     text: "Something went wrong. Please try again.",
                //     icon: "warning",
                //     button: "Ok"
                // });
            })
    }
};

export const ClearSearchedData = () => {
    return dispatch => {
        dispatch({
            type: consts.CLEAR_SEARCH_DATA,
        })
    }
};

//=================================CONTACT==========================================================

export const GetContactsWeb = () => {
    return dispatch => {
        return request(_urlContact, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_CONTACT_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {
            })
    }
};

export const SendContactsEmail = (data) => {
    return dispatch => {
        return request(_urlContact, "POST", false, data)
    }
};

//=================================CUSTOM-PAGE======================================================

export const GetCustomPage = (slug) => {
    return dispatch => {
        return request(`${_urlCustomPage}/${slug}`, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_CUSTOM_PAGE,
                    payload: res.data
                });
                dispatch({
                    type: consts.SET_META_DATA,
                    payload: {
                        title: res.data.metaTitle,
                        description: res.data.metaDescription
                    }
                });
                return res.data
            })
    }
};

export const ClearCustomPage = () => {
    return dispatch => {
        dispatch({
            type: consts.CLEAR_CUSTOM_PAGE,
        })
    }
};


//=================================VACANCIES======================================================

export const GetVacancies = () => {
    return dispatch => {
        return request(`${_urlVacancies}`, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_VACANCIES_DATA,
                    payload: res.data
                });
                return res.data
            })
    }
};

//=================================ABOUT======================================================

export const GetAboutWeb = () => {
    return dispatch => {
        return request(`${_urlAbout}`, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_ABOUT_DATA,
                    payload: res.data
                });
                return res.data
            })
    }
};

//=================================META-DATA========================================================

export const SetMetaData = (data) => {
    return dispatch => {
        dispatch({
            type: consts.SET_META_DATA,
            payload: data
        })
    }
};
export const ClearMetaData = () => {
    return dispatch => {
        dispatch({
            type: consts.CLEAR_META_DATA,
        })
    }
};

//=================================UTILS========================================================

export const ToggleNavigationMenu = (state) => {

    state ? document.body.classList.add("navigation-menu-open"):
    document.body.classList.remove("navigation-menu-open");

    return dispatch => {
        dispatch({
            type: consts.TOGGLE_NAVIGATION_MENU,
            payload: state
        })
    }
};
export const ToggleHomeSection = (state) => {

    return dispatch => {
        dispatch({
            type: consts.TOGGLE_HOME_SECTION,
            payload: state
        })
    }
};

import {createSelector} from "reselect";
import {authSelectorKeys} from "./selectorKeys";

const auth_S = state => state.auth;

const creatorFn = selectorKey => {
    return createSelector(auth_S, data => data[selectorKey])
};

export const authSelectors = state => {
    const sData = {};

    authSelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
//import packages
import React, {Component} from "react";
import {connect} from 'react-redux';

//import style
import "../../assets/styles/pages/about.scss";


//import utils
import {SetMetaData} from "../redux/actions";
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {Link} from "react-router-dom";
import {generateMediaUrl} from "../utils/generateMediaUrl";
import PageHeader from "../components/dataDisplay/PageHeader";
import {changeImageFormat} from "../utils/resizeImage";

class About extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {aboutPageData} = this.props;
        this.props.SetMetaData({
            title: aboutPageData?.metaTitle,
            metaDescription: aboutPageData?.metaDescription
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage, aboutPageData} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.SetMetaData({
                title: aboutPageData?.metaTitle,
                metaDescription: aboutPageData?.metaDescription
            })
        }
    }

    render() {
        const {aboutPageData, translation} = this.props;
        const about = aboutPageData?.sections?.[0];
        const rightImgSection = aboutPageData?.sections?.[1];
        const distribution = aboutPageData?.sections?.[2];
        const management = aboutPageData?.sections?.[3];
        const purpose = aboutPageData?.sections?.[4];
        const purposeTitle = purpose?.title?.split('*');

        const purposeTitleFirstPart = purposeTitle?.[0];
        const purposeTitleLastPart = purposeTitle?.[1];

        const pageHeader = {
            title: translation && translation['about-page-title'],
        };

        return <div className="about-page">
            <div className="about-page-header">
                <PageHeader pageHeader={pageHeader}/>
            </div>
            <div className="about-company">
                {
                    about && <div className='row'>
                        <div className="col-12 col-xl-6 about-company-left-part">
                            <p>{about.title}</p>
                            <div className="about-page-description"
                                 dangerouslySetInnerHTML={{__html: about.description}}/>
                        </div>
                        <div className="col-12 col-xl-6 about-company-right-part">
                            <div className="about-company-right-bg"/>
                            <img className={"w-100"} src={changeImageFormat(generateMediaUrl(about?.mediaMain?.path))}
                                 alt={about?.mediaMain?.altAttribute || ''}/>
                        </div>
                        <div className="about-company-bottom-bg"/>
                    </div>
                }
            </div>
            {
                rightImgSection && <div className='about-second-section row'>
                    <div className="col-12 col-lg-6">
                        <img className="w-100"
                             src={changeImageFormat(generateMediaUrl(rightImgSection?.mediaMain?.path))}
                             alt={rightImgSection?.mediaMain?.altAttribute || ''}
                        />
                    </div>
                    <div className="col-12 col-lg-6 about-second-section-right">
                        <p>{rightImgSection.title}</p>
                        <p dangerouslySetInnerHTML={{__html: rightImgSection.description}}/>
                    </div>
                </div>
            }

            {
                distribution && <div className="about-distribution-section">
                    <div className="row">
                        <div className="col-12 col-xl-6 about-distribution-section-left">
                            <p className={'about-distribution-header'}>{distribution.title}</p>
                            <p dangerouslySetInnerHTML={{__html: distribution.description}}/>
                        </div>
                        <div className="col-12 col-xl-6 about-distribution-section-right pl-0">
                            <div className="about-distribution-right-bg"/>
                            <img className="w-100"
                                 src={changeImageFormat(generateMediaUrl(distribution?.mediaMain?.path))}
                                 alt={distribution?.mediaMain?.altAttribute || ''}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className="about-management-section">
                {
                    management && <div className="row">
                        <div className="col-12 col-md-6 about-management-section-left">
                            <img className="w-100"
                                 src={changeImageFormat(generateMediaUrl(management?.mediaMain?.path))}
                                 alt={management?.mediaMain?.altAttribute || ''}
                            />
                        </div>
                        <div className="col-12 col-md-6 about-management-section-right">
                            <p className={'about-management-header'}>{management.title}</p>
                            <p dangerouslySetInnerHTML={{__html: management.description}}/>
                        </div>
                    </div>
                }

                {
                    purpose && <div className="row about-strategies">
                        <div className="col-12 col-lg-6 about-strategies-left">
                            <p className={'about-strategies-title'}>
                                <span>{purposeTitleFirstPart}</span>
                                <span>{purposeTitleLastPart}</span>
                            </p>
                        </div>
                        <div className="col-12 col-lg-6 about-strategies-left"
                             dangerouslySetInnerHTML={{__html: purpose.description}}/>
                    </div>
                }


            </div>


        </div>
    }
}

const mapDispatchToProps = {
    SetMetaData
}
export default connect(mapStateToProps, mapDispatchToProps)(About)

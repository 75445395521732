import React, {Component} from "react";
import {connect} from "react-redux";
import {history} from "../config/history";

//import styles
import '../../assets/styles/pages/customPage.scss';

//import components
import {SliderModal} from "../components/dataDisplay/SliderModal";
//import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {GetCustomPage, ClearCustomPage, ClearMetaData} from "../redux/actions";
import {generateMediaUrl} from "../utils/generateMediaUrl";
import {Link} from "react-router-dom";
import PageHeader from "../components/dataDisplay/PageHeader";

class CustomPage extends Component {
    constructor() {
        super();
        this.state = {
            initialSlide: 0,
            alertDialogOpen: false,
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    async componentDidMount() {
        const pageSlug = this.props.match && this.props.match.params && this.props.match.params.slug;

        this.props.GetCustomPage(pageSlug).catch(() => {
            history.push('not-found');
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        const prevSlug = prevProps.match && prevProps.match.params && prevProps.match.params.slug;
        const pageSlug = this.props.match && this.props.match.params && this.props.match.params.slug;
        prevSlug !== pageSlug && this.props.GetCustomPage(pageSlug).catch(() => {
            history.push('not-found');
        });
        (selectedLanguage.code !== prevProps.selectedLanguage.code)
        && this.props.GetCustomPage(pageSlug).then((pageData) => {
            pageData && pageData.pageUrl && history.push(`/${pageData.pageUrl}`);
        }).catch(() => {
            history.push('/not-found');
        })

    }

    componentWillUnmount() {
        //this.props.ClearMetaData();
        this.props.ClearCustomPage();
    }

    openModal(initialSlide) {
        this.setState({
            alertDialogOpen: true,
            initialSlide
        })
    }

    closeModal() {
        this.setState({
            alertDialogOpen: false,
            alertMode: ""
        })
    }

    render() {
        const {initialSlide, alertDialogOpen} = this.state;
        const {customPageData} = this.props;
        const pageHeader = {
            title: customPageData?.title
        };

        return <div className="custom-page">
            <PageHeader pageHeader={pageHeader}/>
            {
                !!customPageData && <div className="page-content">
                    <div className={'editor-data ck-content-wrapper'}
                         dangerouslySetInnerHTML={{__html: customPageData.content}}>
                    </div>
                    {!!customPageData.mediaMain && <div className={'image-wrapper'}>
                        <img
                            src={generateMediaUrl(customPageData.mediaMain && customPageData.mediaMain.path)}
                            alt={customPageData.mediaMain ? customPageData.mediaMain.altAttribute : ""}/>
                        <div className="custom-page-blue-bg"/>
                    </div>}

                </div>
            }

            <div className="media-wrapper">
                {
                    customPageData && customPageData.mediaArray && customPageData.mediaArray.length !== 0
                    && customPageData.mediaArray.map((item, index) => {
                        return <div className="media-item" key={item.id} onClick={() => {
                            this.openModal(index)
                        }}>
                            <div className="image">
                                <img src={generateMediaUrl(item.path && item.path)}
                                     alt={item.altAttribute ? item.altAttribute : ""}/>
                            </div>
                        </div>
                    })
                }
            </div>
            <SliderModal
                buttons={["Close"]}
                isOpen={alertDialogOpen}
                closeModal={this.closeModal}
                mediaArray={customPageData?.mediaArray?.length !== 0 && customPageData?.mediaArray}
                initialSlide={initialSlide}
            />
        </div>
    }
}

const mapDispatchToProps = {
    GetCustomPage,
    ClearCustomPage,
    ClearMetaData
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPage);

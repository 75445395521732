import {authConsts, sliderConsts} from "../constants";

const initialState = {
    slidersList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case sliderConsts.GET_SLIDER:
            return {
                ...state,
                slidersList: action.payload
            };
        case sliderConsts.DELETE_SLIDE_BY_ID:
            return {
                ...state,
                slidersList: state.slidersList.filter(slider => slider.id !== action.payload)
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
import React, {Component} from "react";
import GoogleMapReact from "google-map-react";

import "../../assets/styles/googleMap.scss"

const Marker = (props) => {
    return <div className='contact-map-marker'
                onClick={() => props.handleClick(props.lat, props.lng)}>
        <img className={props.isActive ? 'active-map-marker' : ''}
             src={require('../../assets/images/contacts/ic_marker.svg')}/>
    </div>
};

class ContactMap extends Component {
    state = {
        map: null,
    };
    handleClick = (lat, lng) => {
        console.log(lat, lng);
        console.log(this.state.map);
        lat && lng && this.state.map && this.state.map.panTo({
            lat,
            lng
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeMarker) {
        const item =this.props.locations.find(item=>item.id===this.props.activeMarker);
            const lat = item?.location?.latitude;
            const lng = item?.location?.longitude;
            lat && lng && this.state.map && this.state.map.panTo({
                lat,
                lng
            })
        }
    }

    render() {
        const {locations, activeMarker} = this.props;
        return (
            <div style={{height: '100%', width: '100%'}}>
                {locations && <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyCebfcxEAT9cXixTzsWXaCcQa60qY3NMMo"}}
                    defaultCenter={{
                        lat: 40.177634,
                        lng: 44.512704
                    }}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => this.setState({map})}
                >
                    {
                        locations.map((item) => {
                            return item.id === activeMarker ?
                                <Marker
                                    lat={item.location && item.location.latitude}
                                    lng={item.location && item.location.longitude}
                                    zoom={11}
                                    isActive={true}
                                    key={item.id}
                                />
                                :
                                <Marker
                                    lat={item.location && item.location.latitude}
                                    lng={item.location && item.location.longitude}
                                    zoom={11}
                                    key={item.id}
                                    handleClick={this.handleClick}
                                />
                        })
                    }

                </GoogleMapReact>}
            </div>
        );
    }
}

export default ContactMap;

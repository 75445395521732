// Import packages
import React, {lazy, Suspense} from "react";
import {Route, Switch, Router} from "react-router-dom";

// Import utils
import {history} from "../config/history";

// Import pages
import Layout from "../pages/Layout";
import Homepage from "../pages/Homepage";
import CustomPage from "../pages/CustomPage";
import Contact from "../pages/Contact";
import About from "../pages/About";
import NotFound from "../pages/NotFound";
import Search from "../pages/Search";
import PartnerDetails from "../pages/PartnerDetails";
import Partners from "../pages/Partners";
import Vacancies from "../pages/Vacancies";


// Import admin Route
const AdminRoute = lazy(() => import("./AdminRoute"));

export default () => {
    const {location} = history;
    return <Router history={history}>
        {location && !location.pathname.includes("admin") ?
            <Layout>
                <Switch>
                    <Route exact path="/" component={Homepage}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/search" component={Search}/>
                    <Route exact path="/partner-details/:id" component={PartnerDetails}/>
                    <Route exact path="/partners" component={Partners}/>
                    <Route exact path="/vacancies" component={Vacancies}/>
                    <Route exact path="/not-found" component={NotFound}/>
                    <Route exact path="/:slug" component={CustomPage}/>
                    <Route component={NotFound}/>
                </Switch>
            </Layout> :
            <Suspense fallback={<div></div>}>
                <AdminRoute/>
            </Suspense>}
    </Router>
}

import {FRONT_API_URL, HOST_URL} from '../../../admin/redux/api/urls';

export const host = HOST_URL;
export const _urlLanguage = FRONT_API_URL + "/languages";
export const _urlMenu = FRONT_API_URL + "/menu";
export const _urlSlider = FRONT_API_URL + "/slider";
export const _urlPartner = FRONT_API_URL + "/partners";
export const _urlReview = FRONT_API_URL + "/reviews-section";
export const _urlContact = FRONT_API_URL + "/contact";
export const _urlCustomPage = FRONT_API_URL + "/custom-pages";
export const _urlVacancies = FRONT_API_URL + "/vacancies";
export const _urlAbout = FRONT_API_URL + "/about";
export const _urlSearch = FRONT_API_URL + "/search";



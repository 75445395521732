import {pagesConsts,authConsts} from "../constants";

const initialState = {
    pageList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case pagesConsts.GET_PAGES_LIST:
            return {
                ...state,
                pageList: action.payload
            };
        case pagesConsts.UPDATE_PAGE_BY_ID:
            return {
                ...state,
                pageList: state.pageList.map(role =>
                    role.id === action.payload.id ? action.payload : role
                )
            };
        case pagesConsts.DELETE_PAGE_BY_ID:
            return {
                ...state,
                pageList: state.pageList.filter(page => page.id !== action.payload)
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
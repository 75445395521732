// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import throttle from 'lodash/throttle';

// Import components
import SimpleSlider from "../components/homePage/Slider";
import ManagementBlock from "../components/homePage/ManagementBlock";
import DistributionBlock from "../components/homePage/DistributionBlock";
import ReviewBlock from "../components/homePage/ReviewBlock";
import PartnersSlider from "../components/homePage/PartnersBlock";
import ContactBlock from "../components/homePage/ContactBlock";

// Import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {ToggleHomeSection} from "../redux/actions";
import {is_touch_device} from "../utils/helperFunctions";
import {Link} from "react-router-dom";

class Homepage extends Component {
    constructor() {
        super();
        this.section0 = React.createRef();
        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.section3 = React.createRef();
        this.section4 = React.createRef();
        this.section5 = React.createRef();
        this.home = React.createRef();

        this.checkScrolling = this.checkScrolling.bind(this);
        this.checkScrollingTrottle = throttle(this.checkScrolling, 100);
        this.componentIndex = 0;
        this.isScrolling = false;
        this.isEnableScrolling = !is_touch_device() && window.innerWidth > 1000;
        this.state = {
            headerShow: false
        }
    }
    showHeader = () => {
        const position = window.pageYOffset;
        if(this.section1?.current?.offsetTop <= position) {
            this.setState({
                headerShow: true
            })
        } else {
            this.setState({
                headerShow: false
            })
        }
    };

    componentDidMount() {

    window.addEventListener('scroll', this.showHeader, { passive: true });
        document.body.classList.add("in-homepage");
        if (this.isEnableScrolling) {
            document.body.classList.add("hide-scrollbar");
            //this['home'].current.style.transform = `translate3d(0, ${0}px, 0)`;
            this.props.ToggleHomeSection(0);
        }
        window.addEventListener('resize', this.checkScrollingTrottle)
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const sectionIndex = this.props.homeSectionIndex;
        if (sectionIndex !== prevProps.homeSectionIndex && sectionIndex !== this.componentIndex) {
            if (this.isEnableScrolling) {
                this.scrollToSection(sectionIndex);
            } else {
                window.scrollTo({
                    top: document.documentElement.scrollTop + document.documentElement.clientHeight,
                    left: 0,
                    behavior: 'smooth'
                })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.showHeader);
        if (this.isEnableScrolling) {
            document.body.classList.remove("hide-scrollbar");
        }
        document.body.classList.remove("in-homepage");
        window.removeEventListener('resize', this.checkScrollingTrottle)
    }

    checkScrolling() {
        // console.log(window.innerWidth);
        const isEnableScrolling = !is_touch_device() && window.innerWidth > 1000;
        if (isEnableScrolling !== this.isEnableScrolling) {
            this.isEnableScrolling = isEnableScrolling;
            if (this.isEnableScrolling) {
                document.body.classList.add("hide-scrollbar");
                this['home'].current.style.transform = `translate3d(0, ${0}px, 0)`;
                this.props.ToggleHomeSection(0);
                this.componentIndex = 0;
            } else {
                document.body.classList.remove("hide-scrollbar");
            }
            this.forceUpdate();
        }
    }

    scrollToSection(index) {
        let height = 0;
        const pagesHeights = [
            this.section0?.current?.clientHeight,
            this.section1?.current?.clientHeight,
            this.section2?.current?.clientHeight,
            this.section3?.current?.clientHeight,
            this.section4?.current?.clientHeight,
            this.section5?.current?.clientHeight,
        ];
        if (this.componentIndex !== index && index >= 0 && index <= 5) {
            for (let i = 0; i < index; i++) {
                height += pagesHeights[i];
            }
            if (index===5) {
                height = this.home?.current?.clientHeight - window.innerHeight
            }
            // console.log('home', this.home?.current?.clientHeight);
            // console.log('window', window.innerHeight);
            // console.log('constact ', this.section5?.current?.clientHeight);
            // console.log('height', height);
            // console.log('pagesHeights', pagesHeights);
            this['home'].current.style.transform = `translate3d(0, ${-height}px, 0)`;
            this.componentIndex = index;
            this.props.homeSectionIndex !== index && this.props.ToggleHomeSection(this.componentIndex);
        }
    }


    onWheel = (event) => {
        if (!this.isScrolling && this.isEnableScrolling) {
            this.isScrolling = true;
            setTimeout(() => {
                this.isScrolling = false;
            }, 900);

            if (event.deltaY < 0) {
                //console.log('scrolling up');
                this.scrollToSection(this.componentIndex - 1);
            } else if (event.deltaY > 0) {
                this.scrollToSection(this.componentIndex + 1);
                //console.log('scrolling down');
            }
        }
    };
    handleLogoClick=()=>{
        if (this.isEnableScrolling) {
            this.scrollToSection(0);
        } else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }

    render() {
        const {location, homeSectionIndex} = this.props;
        const {headerShow} = this.state;
        let headerClass = (headerShow || (window.innerWidth > 1000 && homeSectionIndex !==0));

        return <div className="homepage-wrapper">
            {<div className={`header-wrapper homepage-header ${headerClass ? 'show' : 'hide' }`}>
                {location?.pathname === '/' ?
                    <img src={require('../../assets/images/header/header.svg')} alt="icon" onClick={this.handleLogoClick}/>
                    : <Link to={'/'}>
                        <img className='search-page-logo' src={require("../../assets/images/header/header.svg")} alt="logo"/>
                    </Link>
                }
            </div>}
            <div className={`homepage`} ref={this.home}
                 onWheel={this.onWheel}>
                <SimpleSlider refProp={this.section0}/>
                <ManagementBlock refProp={this.section1}/>
                <DistributionBlock refProp={this.section2}/>
                <ReviewBlock refProp={this.section3}/>
                <PartnersSlider refProp={this.section4}/>
                <ContactBlock refProp={this.section5}/>
            </div>
        </div>
    }
}

const mapDispatchToProps = {
    ToggleHomeSection
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
// Import styles
import "../../../assets/styles/homePage/slider.scss";


// Import components
import Slider from "react-slick";

// Import utils
import {mapStateToProps} from "../../../admin/redux/mapStateToProps";
import {GetSliderWeb} from "../../redux/actions";
import {generateMediaUrl} from "../../utils/generateMediaUrl";
import {changeImageFormat} from "../../utils/resizeImage";

class SimpleSlider extends Component {
    constructor() {
        super();
        this.state = {
            nav: null,
            firstImageLoaded:false,
        };
        this.sliderRef = React.createRef();
    }

    componentDidMount() {
        this.props.GetSliderWeb();
        this.setState({
            nav: this.slider,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetSliderWeb();
        }
    }

    render() {
        let {sliderList, translation} = this.props;
        let {firstImageLoaded} = this.state;
        const settingsImages = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            pauseOnHover: false,
            autoplaySpeed:5000,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: firstImageLoaded ? 'ondemand' :'progressive',
        };

        return (
            <div className={`home-page-slider  height-100 `} ref={this.props.refProp}>
                <Slider {...settingsImages} asNavFor={this.state.nav} ref={slider => (this.slider = slider)}>
                    {sliderList && sliderList.map((item,index) => {
                        return <div className={"slider-item"} key={index}>
                            <img src={changeImageFormat(generateMediaUrl(item?.mediaMain?.path))} alt={item?.mediaMain?.altAttribute || ""}
                                 onLoad={() => {
                                     !firstImageLoaded && this.setState({firstImageLoaded: true});
                                 }}/>
                            <div className={"col-12 col-md-8 col-lg-6 p-0 slider-caption"}>
                                <div className={'slider-caption-logo'}>
                                    <img src={require('../../../assets/images/header/header.svg')} alt="icon"/>
                                </div>
                                <div className={'slider-description'}>
                                    <div className={'description-header'} dangerouslySetInnerHTML={{__html: item?.title}}/>
                                    <div className={'description-text'}>{item?.description}</div>
                                    <Link className={'description-btn'} to={item?.url}>{translation && translation['home-slider-see-more-link-text']}<img src={require('../../../assets/images/ic_arrow_right_blue.svg')} alt="icon"/></Link>
                                </div>
                            </div>
                        </div>
                    })}
                </Slider>
                { sliderList && sliderList.length > 1 && <div className={'row'}>
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className={'slider-arrows'}>
                            <div className={'slider-btn  prev-btn'} onClick={() => this.slider.slickPrev()}>
                                <img src={require('../../../assets/images/ic_black_left_arrow.svg')} alt="icon"/>
                            </div>
                            <div className={'slider-btn next-btn'} onClick={() => this.slider.slickNext()}>
                                <img src={require('../../../assets/images/ic_black_right_arrow.svg')} alt="icon"/>
                            </div>
                        </div>
                    </div>
                </div>

                }


            </div>
        );
    }
}

const mapDispatchToProps = {
    GetSliderWeb,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleSlider);

import {UserConsts} from "../constants/userConsts";
import {authConsts} from "../constants/authConsts";

const initialState = {
    usersList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UserConsts.GET_USERS:
            return {
                ...state,
                usersList: action.payload
            };
        case UserConsts.UPDATE_USER:
            return {
                ...state,
                usersList: state.usersList.map(user =>
                    user.id === action.payload.id ? action.payload : user
                )
            };
        case UserConsts.DELETE_USER:
            return {
                ...state,
                usersList: state.usersList.filter(user => user.id !== action.payload)
            };
        case UserConsts.CLEAR_USER_DATA:
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
import {authConsts} from "../constants/authConsts";

const initialState = {
    isLoggedIn: false,
    accessToken: "",
    isAdmin: false,
    scope: null,
    username: "",
    accessPages: {
        creatingPages: [],
        readingPages: [],
        updatingPages: [],
        deletingPages: []
    },
    staticPages: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case authConsts.LOG_IN:
            return {
                ...state,
                isLoggedIn: true,
                accessToken: action.payload.accessToken,
                scope: action.payload.scope,
                username: action.payload.user && action.payload.user.username,
                isAdmin: action.payload.scope === "access:admin",
                accessPages: {
                    creatingPages: action.payload.user && action.payload.user.role
                        && action.payload.user.role.creatingPages,
                    readingPages: action.payload.user && action.payload.user.role
                        && action.payload.user.role.readingPages,
                    updatingPages: action.payload.user && action.payload.user.role
                        && action.payload.user.role.updatingPages,
                    deletingPages: action.payload.user && action.payload.user.role
                        && action.payload.user.role.deletingPages,
                },
                staticPages: action.payload.staticPages
            };

        case authConsts.GET_STATIC_PAGES:
            return {
                ...state,
                staticPages: action.payload
            };
        case authConsts.LOG_OUT:
            return {...initialState};
        default:
            return state;
    }
}
import {createSelector} from "reselect";
import {gallerySelectorKeys} from "./selectorKeys";

const gallery_S = state => state.gallery;

const creatorFn = selectorKey => {
    return createSelector(gallery_S, data => data[selectorKey])
};

export const gallerySelectors = state => {
    const sData = {};

    gallerySelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
import {authConsts, menuConsts} from "../constants";
import {MENU_TYPES} from "../../constants/constants";

const initialState = {
    menuList: [],
    singleMenu: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case menuConsts.GET_MENU_LIST:
            return {
                ...state,
                menuList:  action.payload,
            };
        case menuConsts.GET_MENU_BY_ID:
            return {
                ...state,
                singleMenu: action.payload
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

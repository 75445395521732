// Import packages
import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import 'moment/locale/hy-am';
import 'moment/locale/ru';

// Import components
import LayoutNavigation from "../components/LayoutNavigation";
import MetaTags from "../components/MetaTags";

// Import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";

import {
    GetTranslations,
    GetMenuWeb,
    GetLanguages,
    GetContactsWeb,
    GetReviewWeb,
    GetPartnerWeb,
    GetAboutWeb,
    ToggleNavigationMenu,
} from "../redux/actions";
import Footer from "../components/Footer";

class Layout extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        console.log(window.innerWidth)
        console.log(window.innerHeight)
        this.props.GetLanguages();
        this.props.GetMenuWeb();
        this.props.GetTranslations();
        this.props.GetPartnerWeb();
        this.props.GetReviewWeb();
        this.props.GetContactsWeb();
        this.props.GetAboutWeb();
    }

    componentDidUpdate(prevProps) {
        const {selectedLanguage} = this.props;
        if (prevProps.location && this.props.location) {
            if (prevProps.location.pathname !== this.props.location.pathname) {
                window.scroll(0, 0);
            }
        }
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetMenuWeb();
            this.props.GetPartnerWeb();
            this.props.GetReviewWeb();
            this.props.GetContactsWeb();
            this.props.GetAboutWeb();
        }
    }

    render() {
        const {contactData, menuList, location, children, navigationMenuOpened} = this.props;

        return <div className="layout-wrapper custom-scrollbar-y">
            <MetaTags/>
            <LayoutNavigation contactData={contactData}
                              menuList={menuList}/>
            <div className={`content-wrapper-section ${navigationMenuOpened ? 'navbar-opened' : ''}`}>
                {children}
                {
                    location.pathname !== "/" && <Footer/>
                }

                <div className={`expanded-mode  ${navigationMenuOpened ? 'expand_on' : ''}`}
                     onClick={() => this.props.ToggleNavigationMenu(false)}/>
            </div>

        </div>
    }
}

const mapDispatchToProps = {
    GetTranslations,
    GetMenuWeb,
    GetLanguages,
    GetContactsWeb,
    GetReviewWeb,
    GetPartnerWeb,
    GetAboutWeb,
    ToggleNavigationMenu,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));

//import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from 'react-router-dom';
import {history} from "../config/history";
//import styles
import "../../assets/styles/navbars/layoutNavigation.scss";

//import components
import {Menu, Dropdown} from 'antd';

//import utils
import {is_touch_device} from "../utils/helperFunctions";
import {
    GetLanguages,
    ChangeLanguage,
    GetMenuWeb,
    ToggleNavigationMenu,
    GetContactsWeb,
    ToggleHomeSection
} from "../redux/actions";
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {generateMediaUrl} from "../utils/generateMediaUrl";


const {SubMenu} = Menu;
const HOMEPAGE_SECTIONS = [0, 1, 2, 3, 4, 5];

class LayoutNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            menuSelectedItems: [],
        };
        this.getSearchText = this.getSearchText.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onSelectMenu = this.onSelectMenu.bind(this);
        this.scrollToNextSection = this.scrollToNextSection.bind(this);
        this.checkScrolling = this.checkScrolling.bind(this);
        this.isEnableScrolling = !is_touch_device() && window.innerWidth > 1000;
    }

    async componentDidMount() {
        this.props.ToggleNavigationMenu(false);
        //this.props.ToggleHomeSection(getHomePageNextSection())
        window.addEventListener('resize', this.checkScrolling)
    }

    checkScrolling() {
        this.isEnableScrolling = !is_touch_device() && window.innerWidth > 1000;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage, navigationMenuOpened} = this.props;

        if (prevProps.location.pathname !== this.props.location.pathname) {
            //navigationMenuOpened && this.toggleMenu();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.checkScrolling)
    }

    toggleMenu() {
        const {navigationMenuOpened} = this.props;
        this.props.ToggleNavigationMenu(!navigationMenuOpened);
    };

    onSelectMenu(item) {
        this.setState({
            menuSelectedItems: item.selectedKeys
        })
    }

    getSearchText(e) {
        const text = e.target.value;
        this.setState({
            searchText: text
        }, () => !text && this.onSearch())
    }

    onSearch() {
        const {searchText} = this.state;
        history.push({
            pathname: '/search',
            search: `?${searchText}`,
            state: {searchText}
        })
        this.props.ToggleNavigationMenu(false);
        this.setState({
            menuSelectedItems: []
        })
    }

    scrollToNextSection() {
        const {homeSectionIndex} = this.props;
        const nextSection = homeSectionIndex + 1;
        this.props.ToggleHomeSection(this.isEnableScrolling ? nextSection % 6 : nextSection);
    }
    handleMenuItemClick(url){
        const {location} = this.props;

        if(url===location.pathname){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
        this.props.ToggleNavigationMenu(false)
    }
    render() {
        const {searchText} = this.state;
        const {homeSectionIndex, location} = this.props;
        const {languages, selectedLanguage, menuList, navigationMenuOpened, translation, contacts} = this.props;
        const dropdownContent = <Menu>
            {
                languages && languages.map((item) => {
                    return selectedLanguage.code !== item.code &&
                        <Menu.Item key={item.id} onClick={() => {
                            this.props.ChangeLanguage(item)
                        }}>
                            <a>
                                <img className="language-icon"
                                     src={generateMediaUrl(item?.iconPath?.path)}
                                     alt={item?.iconPath?.altAttribute || ""}/>
                                {item.name}
                            </a>
                        </Menu.Item>
                })
            }
        </Menu>;
        // console.log(window.pageYOffset, 'window.pageYOffset');
        return (
            <div className="layout-navigation-wrapper">
                {location?.pathname !== "/" && <div className={`header-wrapper white-header`}>
                    {location?.pathname === '/' ?
                        <img src={require('../../assets/images/header/header.svg')} alt="icon"/>
                        : <Link to={'/'}>
                            <img className='search-page-logo' src={require("../../assets/images/header/header.svg")} alt="logo"/>
                        </Link>
                    }
                </div>}
                <div className={`navMenu`}>
                    <button className="toggle-btn" onClick={this.toggleMenu}>
                        <img src={require('../../assets/images/header/menu.svg')}
                             alt="icon"/>
                    </button>
                    {location?.pathname === "/" &&
                    <>
                        <div className="scrolling-lines">
                            {
                                HOMEPAGE_SECTIONS.map((section, index) => {
                                    return <ul key={section}
                                               className={homeSectionIndex === section ? 'selected-section' : ''}
                                               onClick={() => this.props.ToggleHomeSection(index)}>
                                        <span className="active-section-num">0.{index + 1}</span>
                                        <li className='active-section-line'/>
                                        <li className="default-section-line"/>
                                    </ul>
                                })
                            }
                        </div>
                        <div className={'scrollMenu'} onClick={this.scrollToNextSection}>
                            <img src={require('../../assets/images/icons/ic_arrow_down_long.svg')}
                                 alt="icon"/>
                        </div>
                    </>
                    }
                </div>

                <div className={`opened-menu-content ${navigationMenuOpened ? 'menu-opened' : ''}`}>
                    <div>
                        <div className={'menu-search-container'}>
                            <div className="menu-search-input">
                                <input value={searchText}
                                       onChange={this.getSearchText}
                                       className="menu-search-input"
                                       type="text"
                                       onKeyDown={(e) => (e.key === 'Enter' && this.onSearch())}
                                       placeholder={translation && translation['menu-search-input-placeholder']}/>
                                <img onClick={this.onSearch}
                                     src={require('../../assets/images/search_blue.svg')}
                                     alt="icon"/>
                            </div>

                        </div>
                        {selectedLanguage.name && <div className={`language-selector`}>
                            <Dropdown overlay={dropdownContent}
                                      placement={'bottomRight'}>
                                <span className="menu-language">
                                    <img className="language-icon"
                                         src={generateMediaUrl(selectedLanguage?.iconPath?.path)}
                                         alt={selectedLanguage?.iconPath?.altAttribute || ""}/>
                                    {selectedLanguage.name}
                                    <img
                                        src={require('../../assets/images/icons/ic_drop_down_gray.svg')}
                                        alt="icon"/>
                                </span>
                            </Dropdown>
                        </div>}
                        <button className="close-btn" onClick={this.toggleMenu}>
                            <img src={require('../../assets/images/icons/ic_close.svg')}
                                 alt="icon"/>
                        </button>
                    </div>

                    <Menu className={'navigation-menu'} mode="inline"
                          selectedKeys={this.state.menuSelectedItems}
                          onSelect={this.onSelectMenu}>
                        {menuList && !!menuList.length && menuList.map((item, index) => {
                            return item.subMenu && !!item.subMenu.length ?
                                <SubMenu key={item.id}
                                         className={'menu-item'}
                                         title={item.title}>
                                    {item.subMenu.map((subCategory) => {
                                        return subCategory.subMenu && !!subCategory.subMenu.length ?
                                            <SubMenu key={subCategory.id}
                                                     className={'subMenu'}
                                                     title={subCategory.title}>
                                                {subCategory.subMenu.map((item) => {
                                                    return <Menu.Item key={item.id} className={'menu-item'}>
                                                        <Link to={{
                                                            pathname: `${item.url}`,
                                                        }}>{item.title}</Link>
                                                    </Menu.Item>
                                                })}
                                            </SubMenu>
                                            :
                                            <Menu.Item key={subCategory.id} className={'menu-item'}>
                                                <Link to={{
                                                    pathname: `${subCategory.url}`,
                                                }}>
                                                    {subCategory.title}
                                                </Link>
                                            </Menu.Item>
                                    })}
                                </SubMenu>
                                :
                                <Menu.Item key={item.id} className={'menu-item'}>
                                    <Link to={{
                                        pathname: `${item.url}`,
                                    }}
                                          onClick={()=>this.handleMenuItemClick(item.url)}>
                                        {item.title}
                                    </Link>
                                </Menu.Item>
                        })}
                    </Menu>
                    <ul className={'social-icons'}>
                        <li className={''}>
                            <a href={contacts.fbUrl} target="_blank" rel="noopener noreferrer">
                                <img src={require('../../assets/images/icons/ic_facebook.svg')}
                                     alt="icon"/>
                            </a>
                        </li>
                        <li className={''}>
                            <a href={contacts.lnUrl} target="_blank" rel="noopener noreferrer">
                                <img src={require('../../assets/images/icons/ic_linked_in.svg')}
                                     alt="icon"/>
                            </a>
                        </li>
                        <li className={''}>
                            <a href={contacts.twUrl} target="_blank" rel="noopener noreferrer">
                                <img src={require('../../assets/images/icons/ic_twitter.svg')}
                                     alt="icon"/>
                            </a>
                        </li>
                        <li className={''}>
                            <a href={contacts.inUrl} target="_blank" rel="noopener noreferrer">
                                <img src={require('../../assets/images/icons/ic_instagram.svg')}
                                     alt="icon"/>
                            </a>
                        </li>
                    </ul>
                    {/* {selectedLanguage.name && <div className={`language-selector`}>
                        <Dropdown overlay={dropdownContent}
                                  placement={'topLeft'}>
                                <span className="menu-language">
                                    <img className="language-icon"
                                         src={generateMediaUrl(selectedLanguage?.iconPath?.path)}
                                         alt={selectedLanguage?.iconPath?.altAttribute || ""}/>
                                    {selectedLanguage.name}
                                    <img
                                        src={require('../../assets/images/icons/ic_drop_down_gray.svg')}
                                        alt="icon"/>
                                </span>
                        </Dropdown>
                    </div>}*/}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    GetLanguages,
    ChangeLanguage,
    GetMenuWeb,
    ToggleNavigationMenu,
    GetContactsWeb,
    ToggleHomeSection
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutNavigation));

//import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import "../../assets/styles/pages/searchPage.scss";

//import components
import PageHeader from "../components/dataDisplay/PageHeader";

//import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {GetSearchedData, ClearSearchedData} from "../redux/actions";
import {history} from "../config/history";


class Search extends Component {
    constructor() {
        super();
        this.state = {};
        this.searchPage = this.searchPage.bind(this);
    }

    componentDidMount() {
        const searchText = this.props.location && this.props.location.state && this.props.location.state.searchText;
        this.props.GetSearchedData({searchText})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage, searchedData} = this.props;
        const prevSearchText = prevProps.location && prevProps.location.state && prevProps.location.state.searchText;
        const newSearchText = this.props.location && this.props.location.state && this.props.location.state.searchText;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            searchedData && this.props.GetSearchedData({
                searchText: searchedData.searchText,
                type: this.state.searchType
            });
        }
        if (prevSearchText !== newSearchText) {
            searchedData && this.props.GetSearchedData({
                searchText: newSearchText,
                type: this.state.searchType
            });
        }
        if (prevState.searchType !== this.state.searchType) {
            searchedData && this.props.GetSearchedData({
                searchText: newSearchText,
                type: this.state.searchType
            });
        }
    }

    componentWillUnmount() {
        this.props.ClearSearchedData();
    }



    searchPage(item) {
        switch (item.page) {
            case "VACANCIES" :
                history.push('/vacancies');
                break;
            case "ABOUT" :
                history.push('/about');
                break;
            case "PARTNERS" :
                history.push({
                    pathname: `/partner-details/${item.id}`,
                    state: {partnerId: item.id}
                });
                break;
            case "CUSTOM_PAGES" :
                history.push(`/${item.pageUrl}`);
                break;
        }
    }


    render() {
        const {searchedData, translation} = this.props;
        const pageHeader = {
            title: translation && translation['search-page-title'],
        };
        return <div className="search-page">
            <PageHeader pageHeader={pageHeader}/>

            {
                searchedData && Array.isArray(searchedData) &&  searchedData.map(item => {
                    return <div className="search-card" key={item.id} onClick={() => this.searchPage(item)}>
                        {item.title}
                    </div>
                })
            }
            {
                searchedData?.length === 0  && <div className="no-search-result">
                    {translation && translation['result-not-found']}
                </div>
            }
        </div>

    }
}

const mapDispatchToProps = {
    GetSearchedData,
    ClearSearchedData
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

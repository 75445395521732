// Import packages
import React, {Component} from "react";
import {Link} from "react-router-dom";

//Import styles
import "../../assets/styles/partnerCard.scss"

// Import utils
import {resizeImage} from "../utils/resizeImage";
import {generateMediaUrl} from "../utils/generateMediaUrl";

class PartnerCard extends Component {
    render() {
        const {partner} = this.props;
        return <Link to={{
                pathname: `/partner-details/${partner?.id}`,
                state: {partnerId: partner?.id}
            }}>
                <div className="partner-item-wrapper">
                    <img src={resizeImage(generateMediaUrl(partner?.logoPath?.path))} alt={partner?.logoPath?.altAttribute || ""}/>
                </div>
            </Link>

    }
}

export default PartnerCard;

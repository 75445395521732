import {roleConsts,authConsts} from "../constants";

const initialState = {
    rolesList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case roleConsts.GET_ROLES:
            return {
                ...state,
                rolesList: action.payload
            };
        case roleConsts.UPDATE_ROLE:
            return {
                ...state,
                rolesList: state.rolesList.map(role =>
                    role.id === action.payload.id ? action.payload : role
                )
            };
        case roleConsts.DELETE_ROLE:
            return {
                ...state,
                rolesList: state.rolesList.filter(role => role.id !== action.payload)
            };
        case roleConsts.CLEAR_ROLE_DATA:
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
// Import packages
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

// Import styles
import "../../assets/styles/pages/partner-details.scss";

// Import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {GetPartnerById,SetMetaData} from "../redux/actions";
import {resizeImage} from "../utils/resizeImage";
import {generateMediaUrl} from "../utils/generateMediaUrl";
import {history} from "../config/history";
import {Icon} from "antd";
import PageHeader from "../components/dataDisplay/PageHeader";

class PartnerDetails extends Component {
    state = {
        mounted: false,
    };

    async componentDidMount() {
        // this.props.SetMetaData({
        //     title:'',
        //     metaDescription:''
        // });
        const id = this.props?.match?.params?.id;
        // const id = this.props.location.state?.partnerId;
        await this.props.GetPartnerById(id);
        this.setState({
            mounted: true
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        const id = this.props?.match?.params?.id;
        const prevId = prevProps?.match?.params?.id;

        if (id && prevId && id !== prevId) {
            this.props.GetPartnerById(id);
        }
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetPartnerById(id);
        }
    }

    render() {
        const {singlePartner, translation} = this.props;

        return (
            <div className="partner-details-wrapper">

                <PageHeader />

                <div className="arrow-back">

                    <img className='logo' onClick={() => history.goBack()}
                         src={require("../../assets/images/icons/ic_arrow_back_blue.svg")}
                         alt="logo"/>

                </div>
                <div className="partner-details-content">
                    {this.state.mounted ?
                        <>
                            <div className="main-image">
                                <div className="main-image-wrapper">
                                    <img
                                        src={resizeImage(generateMediaUrl(singlePartner?.logoPath?.path))}
                                        alt={singlePartner?.logoPath?.altAttribute || ""}/>
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <div className="content">
                                    <h2 className="name">{singlePartner?.name}</h2>
                                    <div className="title">{singlePartner?.title}</div>
                                    <div className="description"
                                         dangerouslySetInnerHTML={{__html: singlePartner?.description}}/>

                                    <a href={singlePartner?.url} target="_blank" rel="noopener noreferrer">
                                        <button className="partner-link">{translation && translation['partners-button-text']}</button>
                                    </a>
                                </div>
                            </div>
                            <div className="brands-content">
                                {
                                    singlePartner?.brands?.map(item => {
                                        return <div className="brand-item" key={item.id} onClick={()=>{item.url && window.open(item.url)}}>
                                            <div className="brand-item-wrapper">
                                                <img
                                                    src={resizeImage(generateMediaUrl(item?.logoPath?.path))}
                                                    alt={item?.logoPath?.altAttribute || ""}/>
                                            </div>
                                        </div>
                                    })
                                }

                            </div>
                        </>
                        : <div className={'loading'}>
                            <Icon type="loading" style={{fontSize: 46, color: "#45A1EF"}} spin/>
                        </div>}
                </div>


            </div>
        );
    }
}

const mapDispatchToProps = {
    GetPartnerById,
    SetMetaData
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDetails);

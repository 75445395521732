// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../../assets/styles/pages/partners.scss";

// Import components
import PartnerCard from "../components/PartnerCard";
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {Link} from "react-router-dom";
import {SetMetaData} from "../redux/actions";
import PageHeader from "../components/dataDisplay/PageHeader";

class Partners extends Component {
    componentDidMount() {
        // this.props.SetMetaData({
        //     title: '',
        //     metaDescription: ''
        // })
    }

    render() {
        let {partnersList, translation} = this.props;
        const pageHeader = {
            title: translation && translation['partners-page-title'],
        };
        return (
            <div className="partners-page-wrapper">
                <PageHeader pageHeader={pageHeader}/>
                <div className="partners-wrapper">
                    {partnersList && partnersList.map((item, index) => {
                        return <div className={"partner-item"} key={index}>
                            <PartnerCard partner={item}/>
                        </div>
                    })}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    SetMetaData
};
export default connect(mapStateToProps, mapDispatchToProps)(Partners);

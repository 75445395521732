import {consts} from "../constants";
import {StaticTextFields} from "../../constants/StaticTexsts";
import {_urlReview} from "../api/url";

export const initialState = {
    languages: [],
    selectedLanguage: '',
    translationsData: StaticTextFields,
    translation: StaticTextFields.hy,

    menuList: [],
    sliderList: [],

    partnersList: [],
    singlePartner: null,

    searchedData: [],

    newsDetails: null,
    reviewList: [],
    contacts: [],
    customPageData: {},
    vacanciesPageData: {},
    aboutPageData: {},
    metaTags: {
        title: '',
        description: ''
    },
    navigationMenuOpened: false,
    homeSectionIndex:null,
};

export default (state = initialState, action) => {

    switch (action.type) {

//Translations
        case consts.SET_ALL_LANGUAGE_WEB:
            const mainLanguage = action.payload.find(lg => lg.isMain);
            const selectedLanguageIsExist = state.selectedLanguage && state.selectedLanguage.id
                && action.payload.find(lg => lg.id === state.selectedLanguage.id);

            const newSelectedLanguage = selectedLanguageIsExist ? state.selectedLanguage :
                (mainLanguage ? mainLanguage : action.payload[0]);

            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: newSelectedLanguage,
            };
            if (state.translationsData && state.translationsData[newSelectedLanguage.code]) {
                newState.translation = state.translationsData[newSelectedLanguage.code]
            }
            return newState;

        case consts.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
                translation: (state.translationsData && state.translationsData[action.payload.code]) || {}
            };

        case consts.SET_TRANSLATIONS:
            return {
                ...state,
                translationsData: action.payload,
                translation: (action.payload && state.selectedLanguage &&
                    state.selectedLanguage.code && action.payload[state.selectedLanguage.code]) || action.payload.hy
            };


// Menu && Slider
        case consts.SET_MENU:
            return {
                ...state,
                menuList: action.payload
            };

        case consts.SET_SLIDER:
            return {
                ...state,
                sliderList: action.payload
            };

//Search reducer
        case consts.SET_SEARCH_DATA:
            return {
                ...state,
                searchedData: action.payload
            };
        case consts.CLEAR_SEARCH_DATA:
            return {
                ...state,
                searchedData: initialState.searchedData
            };
// Partners reducer
        case consts.SET_ALL_PARTNERS_WEB:
            return {
                ...state,
                partnersList: action.payload
            };

        case consts.SET_PARTNER_BY_ID:
            return {
                ...state,
                singlePartner: action.payload
            };
        case consts.CLEAR_PARTNER_DETAILS:
            return {
                ...state,
                singlePartner: null,
            };
//Review reducer
        case consts.SET_REVIEW_WEB:
            return {
                ...state,
                reviewList: action.payload
            };
//Contact reducer
        case consts.SET_CONTACT_WEB:
            return {
                ...state,
                contacts: action.payload
            };

//Custom pages
        case consts.SET_CUSTOM_PAGE:
            return {
                ...state,
                customPageData: action.payload
            };
        case consts.CLEAR_CUSTOM_PAGE:
            return {
                ...state,
                customPageData: null,
            };

//Vacancies
        case consts.SET_VACANCIES_DATA:
            return {
                ...state,
                vacanciesPageData: action.payload
            };
//About
        case consts.SET_ABOUT_DATA:
            return {
                ...state,
                aboutPageData: action.payload
            };

//Meta tags
        case consts.SET_META_DATA:
            return {
                ...state,
                metaTags: {
                    title: action.payload.title || "",
                    description: action.payload.description || "",
                }
            };
        case consts.CLEAR_META_DATA:
            return {
                ...state,
                metaTags: initialState.metaTags
            };

//Utils
        case consts.TOGGLE_NAVIGATION_MENU:
            return {
                ...state,
                navigationMenuOpened: action.payload
            };
        case consts.TOGGLE_HOME_SECTION:
            return {
                ...state,
                homeSectionIndex: action.payload
            };
        default:
            return state;
    }
}

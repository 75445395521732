// Import packages
import React, {Component} from "react";
import {Link} from "react-router-dom";

//Import styles
import "../../../assets/styles/dataDisplay/pageHeader.scss"

export default class PageHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {pageHeader} = this.props;
        return <div className="page-header">
            {/*<Link to={'/'}>*/}
            {/*    <img className='search-page-logo' src={require("../../../assets/images/header/header.svg")} alt="logo"/>*/}
            {/*</Link>*/}
            <h2 className="page-title">{pageHeader?.title} </h2>
        </div>
    }
}
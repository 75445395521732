import React, {PureComponent} from 'react';
import {
    withRouter,
} from 'react-router-dom';
import {connect} from 'react-redux';
import logo from '../../assets/images/header/header.svg';
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import Meta_Tags from 'react-meta-tags';

class MetaTags extends PureComponent {

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     return nextProps.location.pathname !== this.props.location.pathname
    // }

    getPageInfo(pathname) {
        const [start, root, id] = pathname.split('/');
        const {translation, metaTags} = this.props;
        const defaultTitle = translation && translation['default-title'];
        const defaultDescription = translation && translation['default-description'];

        switch (root) {
            case '':
            case 'search':
            case 'partners':
            case 'partner-details':
            case 'not-found':
                return {
                    newTitle: defaultTitle,
                    newDescription: defaultDescription,
                };

            default:
                return {
                    newTitle: metaTags && metaTags.title,
                    newDescription: metaTags && metaTags.description,
                }
        }
    }

    render() {
        const {
            newTitle,
            newDescription,
            newImage,
        } = this.getPageInfo(this.props.location.pathname);
        const {translation} = this.props;
        const defaultTitle = (translation && translation['default-title']) || "CFCO";
        const defaultDescription = (translation && translation['default-description']) || 'CFCO';

        // const title = newTitle || defaultTitle;
        // const description = newDescription || defaultDescription;
        const title = defaultTitle;
        const description = defaultDescription;

        const image = newImage || logo;
        return (<Meta_Tags>
                <title>{title}</title>
                <meta property="og:title" content={title}/>

                <meta name="description" content={description}/>
                <meta property="og:description" content={description}/>
                <meta property="og:image" content={image}/>
            </Meta_Tags>
        )
    }
}

export default connect(mapStateToProps)(withRouter(MetaTags));

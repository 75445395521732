//----------------------------------cfCom-server----------------------------------------------

const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL || "https://admin-api.cfcom.am/api/v1";

const FRONT_API_URL = process.env.REACT_APP_FRONT_API_URL || "https://front-api.cfcom.am/api/v1";

const HOST_URL =  process.env.REACT_APP_HOST_URL || "https://static.cfcom.am";
//const HOST_URL =  process.env.REACT_APP_HOST_URL || "https://admin-api.cfcom.am";

//----------------------------------studio-one-server----------------------------------------------

// const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL || "https://admin-api.cfcom.studio-one.am/api/v1";
//
// const FRONT_API_URL = process.env.REACT_APP_FRONT_API_URL || "https://front-api.cfcom.studio-one.am/api/v1";
//
// const HOST_URL =  process.env.REACT_APP_HOST_URL || "https://admin-api.cfcom.studio-one.am";


//----------------------------------4steps-server---------------------------------------------------

// const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL || "http://173.249.20.192:1010/api/v1";
//
// const FRONT_API_URL = process.env.REACT_APP_FRONT_API_URL  || "http://173.249.20.192:1000/api/v1";
//
// const HOST_URL = process.env.REACT_APP_HOST_URL || "http://173.249.20.192:1010";



export const _urlOauth = ADMIN_API_URL + "/jwt";
export const _urlStaticPage = ADMIN_API_URL + "/static-pages";
export const _urlUser = ADMIN_API_URL + "/users";
export const _urlRole = ADMIN_API_URL + "/roles";
export const _urlLanguage = ADMIN_API_URL + "/languages";
export const _urlPartners = ADMIN_API_URL + "/partners";
export const _urlMedia = ADMIN_API_URL + "/files";
export const _urlMenu = ADMIN_API_URL + "/menu";
export const _urlPages = ADMIN_API_URL + "/custom-pages";
export const _urlSlider = ADMIN_API_URL + "/slider";
export const _urlContact = ADMIN_API_URL + "/contacts";
export const _urlReview = ADMIN_API_URL + "/reviews-section";
export const _urlVacancies = ADMIN_API_URL + "/vacancies";
export const _urlAbout = ADMIN_API_URL + "/about";
export const _urlStatic = ADMIN_API_URL + "/static-texts";
export {FRONT_API_URL, HOST_URL};


export const consts = {
    //Languages
    SELECTED_LANGUAGE: "SELECTED_LANGUAGE",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    SET_ALL_LANGUAGE_WEB: "SET_ALL_LANGUAGE_WEB",

    //Static Texts
    SET_TRANSLATIONS: "SET_TRANSLATIONS",

    //Menu && Slider
    SET_MENU: "SET_MENU",
    SET_SLIDER: "SET_SLIDER",

    //Partners
    SET_ALL_PARTNERS_WEB: "SET_ALL_PARTNERS_WEB",
    SET_PARTNER_BY_ID: "SET_PARTNER_BY_ID",
    CLEAR_PARTNER_DETAILS: "CLEAR_PARTNER_DETAILS",

    //Review
    SET_REVIEW_WEB: "SET_REVIEW_WEB",

    //Contact
    SET_CONTACT_WEB: "SET_CONTACT_WEB",

    //Custom Page
    SET_CUSTOM_PAGE: "SET_CUSTOM_PAGE",
    CLEAR_CUSTOM_PAGE: "CLEAR_CUSTOM_PAGE",

    //Vacancies Page
    SET_VACANCIES_DATA: "SET_VACANCIES_DATA",

    //About Page
    SET_ABOUT_DATA: "SET_ABOUT_DATA",

    //Search Page
    SET_SEARCH_DATA: "SET_SEARCH_DATA",
    CLEAR_SEARCH_DATA: "CLEAR_SEARCH_DATA",

    //Meta Tags
    SET_META_DATA: "SET_META_DATA",
    CLEAR_META_DATA: "CLEAR_META_DATA",

    //Utils
    TOGGLE_NAVIGATION_MENU:"TOGGLE_NAVIGATION_MENU",
    TOGGLE_HOME_SECTION:"TOGGLE_HOME_SECTION",


};

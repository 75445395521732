import {authConsts, partnerConsts} from "../constants";

export const initialState = {
    partnersList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case partnerConsts.GET_ALL_PARTNERS:
            return {
                ...state,
                partnersList: action.payload.data
            };
        case partnerConsts.DELETE_PARTNER_BY_ID:
            return {
                ...state,

                partnersList: state.partnersList.filter(partner => partner.id !== action.payload)

            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

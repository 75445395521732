// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// Import utils
import {mapStateToProps} from "../../../admin/redux/mapStateToProps";
import {GetSliderWeb} from "../../redux/actions";
import {generateMediaUrl} from "../../utils/generateMediaUrl";

// Import styles
import "../../../assets/styles/homePage/partners-block.scss";

// Import components
import Slider from "react-slick";
import {resizeImage} from "../../utils/resizeImage";
import {LinkButton} from "../dataDisplay/UIElements";
import PartnerCard from "../PartnerCard";

class PartnersSlider extends Component {
    constructor() {
        super();
        this.state = {
            nav1: null,
            nav2: null
        };
        this.sliderRef = React.createRef();
    }

    componentDidMount() {
        // this.props.GetSliderWeb();
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            // this.props.GetSliderWeb();
        }
    }

    getSlidesToShowCount() {
        const innerWidth = window.innerWidth;
        return innerWidth >= 1200 ? 6
            : (innerWidth >= 900 ? 4
                : (innerWidth >= 600 ? 3
                    : (innerWidth >= 480 ? 2
                        : (innerWidth >= 300 ? 1 : 0))))
    }

    render() {
        let {partnersList, translation} = this.props;
        const settingsImages = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="partners-slider"  ref={this.props.refProp}>
                <p className={'hompage-partners-header'}>{translation && translation['home-partners-section-title']}</p>
                <Slider {...settingsImages} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)}>
                    {partnersList && partnersList.map((item, index) => {
                        return <div className={"slider-item"} key={index}>
                            <PartnerCard partner={item}/>
                        </div>
                    })}
                </Slider>
                <div className={'px-2 mt-4 slider-arrows-block'}>
                     <LinkButton name={translation && translation['home-partners-section-see-more-button']} link={'/partners'}/>
                    {this.getSlidesToShowCount() < partnersList?.length && <div className={'row'}>

                        <div className="col-12 col-md-6">
                            <div className={'slider-arrows'}>
                                <div className={'slider-btn  prev-btn'} onClick={() => this.slider1.slickPrev()}/>
                                <div className={'slider-btn next-btn'} onClick={() => this.slider1.slickNext()}/>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    GetSliderWeb,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnersSlider);

// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from 'react-router-dom';
import {history} from "../config/history";
// Import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";

// Import styles
import "../../assets/styles/notFound.scss";

import PageHeader from "../components/dataDisplay/PageHeader";

class NotFound extends Component {

    render() {
        const {leftSideMenuOpen, translation} = this.props;
        const inCMS = history.location&&  history.location.pathname&&  history.location.pathname.includes('admin');
        const noDataMessage = this.props.location && this.props.location.state && this.props.location.state.noDataMessage;

        return <div className={`not-found-page ${leftSideMenuOpen ? "size-sm" : ""}`}>
            {
                !inCMS && <div className="header-logo">
                    <PageHeader/>
                </div>
            }
            <div className="inner-content">

                <img src={require('../../assets/images/page_not_found.svg')}
                     alt=""/>
                {
                    inCMS && <span className="text-message">{noDataMessage? noDataMessage: (translation && translation["page-notFound-text"])}</span>
                }
            </div>
        </div>
    }
}

export default connect(mapStateToProps)(withRouter(NotFound));

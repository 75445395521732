import React from "react";
import Modal from "react-modal";
import Slider from "react-slick";
import {generateMediaUrl} from "../../utils/generateMediaUrl";

// Import styles
import "../../../assets/styles/dataDisplay/sliderModal.scss"

function SampleNextArrow(props) {
    const {onClick } = props;
    return (
        <div className="slider-right-arrow"
            onClick={onClick}
        >
            <img src={require('../../../assets/images/icons/ic_arrow_slider_right.svg')}/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="slider-left-arrow"
             onClick={onClick}
        >
            <img src={require('../../../assets/images/icons/ic_arrow_slider_left.svg')}/>
        </div>
    );
}

Modal.setAppElement('#root')
export function SliderModal(props){
    const { isOpen, closeModal, mediaArray, initialSlide} = props;
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: initialSlide
    };
    const rootElement = document.getElementById("root");

    return <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="slider-modal"
        closeTimeoutMS={150}
        overlayClassName="slider-modal-overlay"
    >
            <div className="slider-modal-content">
                <button className="dialog-closing-btn"
                        onClick={closeModal}>
                    <img src={require('../../../assets/images/ic_close.svg')}/>
                </button>
                <Slider {...settings}>
                    {
                        mediaArray && mediaArray.map((item)=>{
                            return <div key={item.id} className="img-content">
                                <img src={generateMediaUrl(item.path)}  alt={item ? item.altAttribute : ''} />
                            </div>
                        })
                    }
                </Slider>
            </div>
        </Modal>
}
// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// Import utils
import {mapStateToProps} from "../../../admin/redux/mapStateToProps";

//Import components
import {LinkButton} from "../dataDisplay/UIElements";

// Import styles
import "../../../assets/styles/homePage/management-block.scss";
import {generateMediaUrl} from "../../utils/generateMediaUrl";
import {changeImageFormat} from "../../utils/resizeImage";

class ManagementBlock extends Component {

    render() {
        const {partnersList, aboutPageData, translation} = this.props;
        const management = aboutPageData?.sections?.[3];
        return <div className={'management-wrapper'} ref={this.props.refProp}>
            {
                management && <div className={'row m-0'}>
                    <div className={'col-12 col-lg-6 management-left-block'}>
                        <div className={'w-100 management-left-content'}>
                            <p className={'management-header'}>{management?.title}</p>
                            <p className={'management-text'}
                               dangerouslySetInnerHTML={{__html: management?.description}}/>
                            {/*<div className={'management-links'}>*/}
                            {/*    { partnersList && partnersList.slice(0, 5).map((item, index) => {*/}
                            {/*        return <Link to={{*/}
                            {/*            pathname: `/partner-details/${item?.id}`,*/}
                            {/*            state: {partnerId: item.id}*/}
                            {/*        }}*/}
                            {/*                     key={index}>{item.name}</Link>*/}
                            {/*    })}*/}
                            {/*</div>*/}
                        </div>
                        <div className={'management-read-more-btn'}>
                            <LinkButton link={"/about"}
                                        name={translation && translation['home-management-section-see-more-button']}/>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 p-0 management-right-block">
                        <img className={'w-100'} src={changeImageFormat(generateMediaUrl(management?.mediaMain?.path))}
                             alt={management?.mediaMain?.altAttribute || ""}/>
                    </div>
                </div>
            }


        </div>;
    }
}


export default connect(mapStateToProps)(ManagementBlock);

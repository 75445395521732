import React from "react";
import {Link} from "react-router-dom";

import {MaterialIcon} from "../utils/MaterialIcon";

import "../../../assets/styles/dataDisplay/uiElements.scss";

export function HamburgerButton(props) {
    const {isOpen, onClick} = props;
    return <button className={`hamburger-btn ${isOpen ? "is-open" : ""}`}
                   onClick={onClick}/>
}

export function ToTopButton(props) {
    const {visible} = props;
    return <button className={`to-top-button ${visible ? "show" : ""}`}
                   onClick={() => window.scroll(0, 0)}>
        <MaterialIcon icon="expand_less"/>
    </button>
}

export function FormInput(props) {
    const {type, cb, name, value, placeholder, maxLength, error, label, link} = props;
    return type !== "textarea" ?
        <>
            <label>{label}<span>*</span></label>
            <input type={type}
                   name={name}
                   value={value}
                   onChange={cb}
                   maxLength={maxLength}
                   placeholder={placeholder}
                   autoComplete={'off'}
                   className={`form-input ${error ? "invalid" : ""}`}/>
        </>
        :
        <>
            <label>{label}<span>*</span></label>
            <textarea name={name}
                      value={value}
                      onChange={cb}
                      maxLength={maxLength}
                      placeholder={placeholder}
                      className={`form-input ${error ? "invalid" : ""}`}/>
         </>
}
export function MaterialIcons(props) {
    const {name} = props;
    return <i className="material-icons">{name}</i>
}
export function LinkButton(props) {
    const {name, link} = props;
    return <Link to={link} className="link-button">{name}</Link>
}
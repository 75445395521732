// Import packages
import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {GetCategoriesWeb, GetMenuWeb, GetContactsWeb, SetSelectedCategory} from "../redux/actions";

// Import styles
import "../../assets/styles/layoutFooter.scss";

class Footer extends React.PureComponent {

    render() {
        const {translation} = this.props;

        return <footer className="layout-footer">
            <div className="left">C&F Co.</div>
          {/*  <a href='https://www.studio-one.am/' target='_blank'>
                <div className="right">{translation && translation['developed-by-company']}</div>
            </a>*/}

        </footer>
    }
}

const mapDispatchToProps = {
    GetMenuWeb,
    GetContactsWeb,
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);

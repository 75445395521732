// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// Import utils
import {mapStateToProps} from "../../../admin/redux/mapStateToProps";

// Import styles
import "../../../assets/styles/homePage/contact-block.scss";
//Import components
import {FormInput, LinkButton} from "../dataDisplay/UIElements.js";
import ContactMap from "../ContactMap";
import {GetContactsWeb, SendContactsEmail} from "../../redux/actions";
import {closeAlertModal, openAlertModal} from "../../../admin/utils/commonFunctions";
import isEmail from "validator/lib/isEmail";
import {AlertDialog} from "../dataDisplay/alertDialog";
import {Icon, Spin} from "antd";

//import utils
import {recaptchaKey} from "../../redux/api/apiKey";

class contactBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contactData: {
                name: "",
                email: "",
                message: "",
            },
            errors: {
                name: false,
                email: false,
                message: false,
            },
            alertDialogOpen: false,
            alertMode: "",
            requestLoading: false
        };
        this.recaptcha=React.createRef();
        this.closeAlertModal = closeAlertModal.bind(this);
        this.openAlertModal = openAlertModal.bind(this);
        this.getInputValues = this.getInputValues.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }


    getInputValues(e) {
        const {name, value} = e.target;

        this.setState({
            contactData: {
                ...this.state.contactData,
                [name]: value,
            },
        })
    }

    async sendEmail() {
        await this.setState({
            requestLoading: true
        });

        const {contactData} = this.state;

        const errors = {
            name: false,
            email: false,
            message: false,
        };
        let allFieldsIsValid = true;
        Object.keys(contactData).forEach((key) => {
            if (!contactData[key]) {
                errors[key] = true;
                allFieldsIsValid = false;
            }
            if (key === 'email' && !isEmail(contactData[key])) {
                errors[key] = true;
                allFieldsIsValid = false;
            }
        });
        if (allFieldsIsValid) {
            window?.grecaptcha && window.grecaptcha.execute(recaptchaKey, {action: 'send_email'}).then(token=> {
                console.log(token)
                const reqData = {
                    name: contactData.name,
                    email: contactData.email,
                    message: contactData.message,
                    recaptchaToken:token
                };
                this.props.SendContactsEmail(reqData).then(() => {
                    this.setState({
                        contactData: {
                            name: "",
                            email: "",
                            message: "",
                        },
                        errors:{
                            name: false,
                            email: false,
                            message: false,
                        },
                    })
                }).catch(() => {

                }).finally(() => {
                    this.setState({
                        requestLoading: false
                    })
                })
            });

        } else {
            this.setState({
                errors,
                requestLoading: false
            })
        }
    }

    render() {
        const spinIcon = <Icon type="loading" style={{fontSize: 16, color: "#45A1EF"}} spin/>;
        const {contactData, requestLoading, errors, alertDialogOpen, alertMode,recaptchaVerified } = this.state;
        const {contacts, translation, selectedLanguage} = this.props;
        return (
            <div className="contact-block"  ref={this.props.refProp}>
                <div className={'contact-wrapper'}>
                    <div className="contact-left-part">
                        <p className={'contact-header'}>{translation && translation['home-contacts-section-title']}</p>
                        <div className={'contact-left-box'}>
                            <div className={errors.name ? 'input-error contact-input' : 'contact-input'}>
                                <FormInput name={'name'}
                                           type={'input'}
                                           label={translation && translation['home-contacts-section-input-name-label']}
                                           value={contactData.name || ""}
                                           error={errors.name}
                                           cb={this.getInputValues}
                                />
                            </div>
                            <div className={errors.email ? 'input-error contact-input' : 'contact-input'}>
                                <FormInput name={'email'}
                                           type={'input'}
                                           label={translation && translation['home-contacts-section-input-email-label']}
                                           value={contactData.email || ""}
                                           error={errors.email}
                                           cb={this.getInputValues}
                                />
                            </div>
                            <div className={errors.message ? 'input-error contact-input' : 'contact-input'}>
                                <FormInput name={'message'}
                                           type={'textarea'}
                                           label={translation && translation['home-contacts-section-input-message-label']}
                                           value={contactData.message || ""}
                                           error={errors.message}
                                           cb={this.getInputValues}
                                />
                            </div>
                            <div className={`recaptcha-wrapper`}/>

                            <button className="link-button" onClick={this.sendEmail}>
                                <div className="flex-row">

                                    {translation && translation['home-contacts-section-send-email-button-text']}
                                    {requestLoading && <Spin indicator={spinIcon}/>}

                                </div>
                            </button>
                            {/*<LinkButton name={'Отправить'} onClick={this.sendEmail}/>*/}
                            <div className={'contact-left-text'}>{translation && translation['home-contacts-section-required-label']}<span>*</span></div>
                        </div>
                    </div>
                    <div className={'contact-right-part'}>
                        <div className={'map-bg'}/>
                        <div className={'map-block'}>
                            <div className={'map'}>
                                <ContactMap locations={contacts?.locations}/>
                                <LinkButton name={translation && translation['home-contacts-section-view-all-regions-button']} link={'/contact'}/>
                            </div>
                            <div className={'contact-information'}>
                                <div>
                                    <p className={'info-header'}>{translation && translation['home-contacts-section-address-title']}</p>
                                    <p className={'contact-info-text'}>{contacts?.address}</p>
                                </div>
                                <div>
                                    <p className={'info-header'}>{translation && translation['home-contacts-section-phone-title']}</p>
                                    <p className={'contact-info-text'}>{contacts?.phone}</p>
                                </div>
                                <div>
                                    <p className={'info-header'}>{translation && translation['home-contacts-section-email-title']}</p>
                                    <p className={'contact-info-text'}>{contacts?.email}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={'contact-bottom'}></div>
                <AlertDialog title="Success!"
                             message="Your email successfully send"
                             buttons={["Close"]}
                             isOpen={alertDialogOpen && alertMode === "success"}
                             closeModal={() => this.closeAlertModal()}/>
            </div>
        );
    }
}


const mapDispatchToProps = {
    SendContactsEmail,
    GetContactsWeb,
};

export default connect(mapStateToProps, mapDispatchToProps)(contactBlock);

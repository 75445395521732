export function toggleItem(toggleItem) {
    this.setState({
        [toggleItem]: !this.state[toggleItem]
    })
}

export function openAlertModal(alertMode, removingIndex) {
    const stateData = {
        alertDialogOpen: true,
        alertMode
    };
    removingIndex && (stateData.removingIndex = removingIndex);
    this.setState(stateData);
}

export function closeAlertModal(removingIndex) {
    const stateData = {
        alertDialogOpen: false,
        alertMode: ''
    };
    removingIndex === null && (stateData.removingIndex = null);
    this.setState(stateData);
}

export function changeTabs(activeTab) {
    this.setState({
        activeTab
    });
}

export function getSelectData(data, field, value) {
    this.setState({
        [data]: {
            ...this.state[data],
            [field]: value
        }
    })

}

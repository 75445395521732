import {authConsts, staticTextConsts} from "../constants";

const initialState = {
    staticTextsList: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case staticTextConsts.GET_STATIC_TEXTS:
            return {
                staticTextsList: action.payload
            };
        case staticTextConsts.UPDATE_STATIC_TEXTS:
            const changeLanguage = state.staticTextsList[action.payload.code]
            return {
                staticTextsList: {
                    ...state.staticTextsList,
                    [action.payload.code]: {
                        ...changeLanguage,
                        [action.payload.key]: action.payload.value
                    }
                }
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

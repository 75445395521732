// Import packages
import React from "react";
import Modal from "react-modal";

// Import styles
import "../../../assets/styles/dataDisplay/alertDialog.scss";

export function AlertDialog(props) {
    const {
        title, subTitle, message, buttons, isOpen, closeModal, callback
    } = props;
    const rootElement = document.getElementById("root");

    return <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        appElement={rootElement}
        className="alert-modal"
        closeTimeoutMS={150}
        overlayClassName="alert-modal-overlay"
    >
        <div className="alert-dialog">
            <div className="dialog-header">
                <div className="dialog-title">
                    <h3>{title}</h3>
                    {subTitle && <h5>{subTitle}</h5>}
                </div>
                <button className="dialog-closing-btn"
                        onClick={closeModal}>
                    <i className="material-icons">close</i>
                </button>
            </div>
            {message && <div className="dialog-content">
                <p>{message}</p>
            </div>}
            <div className="action-buttons">
                {buttons.map((button, index) => {
                    return (
                        <button className={button}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (index === 0) {
                                        closeModal();
                                    } else {
                                        callback();
                                        closeModal();
                                    }
                                }}
                                key={index}>
                            {button}
                        </button>
                    )
                })}
            </div>
        </div>
    </Modal>
}
export const LANGUAGES = [
    {
        id: 1,
        title: "EN"
    },
    {
        id: 2,
        title: "RU"
    },
];
export const DATE_FORMAT = 'DD/MM/YYYY HH:mm';

//file upload
export const MAX_FILE_SIZE = 209715200;
export const ACCEPT_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'pdf', 'wmv', 'mpeg',
    'avi', 'flv', 'mp3', 'wav', 'zip', 'rar', 'doc', 'docx', 'xls', 'xlsx', 'ppt','pptx'];
export const IMAGES_TYPES_PATTERN = "(jpg|jpeg|gif|png|svg|bmp)$";

//media modal
export const MEDIA_MODAL_TYPES = {
    MEDIA_MAIN: 'MEDIA_MAIN',
    MEDIA_ARRAY: 'MEDIA_ARRAY',
};


export const NEWS_MEDIA_TYPES = {
    MAIN: 'MAIN',
    VIDEO: 'VIDEO',
};


export const MENU_TYPES = {
    MAIN: 'MAIN',
    RIGHT: 'RIGHT',
};


export const MENU_MAX_DEEP = 2;

// by pagination GET request limits

export const LIMIT_GET_NEWS = 10;
export const LIMIT_GET_MEDIA = 20;

// vacancies page types
export const VacanciesType_Const = {
    AREAS: 'AREAS',
    SECTIONS: 'SECTIONS',
};

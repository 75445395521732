// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// Import utils
import {mapStateToProps} from "../../../admin/redux/mapStateToProps";
import {GetSliderWeb} from "../../redux/actions";
import {generateMediaUrl} from "../../utils/generateMediaUrl";

// Import styles
import "../../../assets/styles/homePage/distribution-block.scss";
import {changeImageFormat, resizeImage} from "../../utils/resizeImage";

import {LinkButton} from "../dataDisplay/UIElements";

class DistributionBlock extends Component {



    render() {
        const {aboutPageData, translation} = this.props;
        const distribution = aboutPageData?.sections?.[2];
        return <div className={'distribution-wrapper'} ref={this.props.refProp}>
            <div className={'row'}>
                <div className="col-12 col-lg-7 distribution-left-block">

                    <div className={'col-12 col-lg-11 p-0 distribution-left-content'}>
                       <img src={changeImageFormat(generateMediaUrl(distribution?.mediaMain?.path))} alt={distribution?.mediaMain?.altAttribute || ""}/>

                    </div>
                </div>
                <div className={'col-12 col-lg-5 distribution-right-block'}>
                    <div className={'distribution-right-content'}>
                        <p className={'distribution-header'}>{distribution?.title}</p>
                        <p className={'distribution-text'} dangerouslySetInnerHTML={{__html: distribution?.description}}/>
                    </div>
                    <div className={'distribution-read-more-btn'}>
                        <LinkButton link={"/about"} name={translation && translation['home-distribution-section-see-more-button']}/>
                    </div>
                </div>
            </div>
        </div>;
    }
}

const mapDispatchToProps = {
    GetSliderWeb,
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributionBlock);

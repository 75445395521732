import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import user from "./user";
import role from "./role";
import partner from "./partner";
import utils from "./utils";
import language from './language';
import gallery from "./gallery";
import menu from "./menu";
import page from "./page";
import staticText from "./staticText";
import files from "./files";
import contact from "./contact";
import member from "./review";
import slider from "./slider";
import about from "./about";

import web from "../../../app/redux/reducer"

export default combineReducers({
    auth,
    role,
    user,
    utils,
    partner,
    language,
    gallery,
    menu,
    page,
    files,
    contact,
    member,
    slider,
    staticText,
    about,
    web,
});

export const StaticTextFields = {
    "hy": {
        "default-title": "C&F Co.",
        "default-description": "C&F Co.",
        "menu-search-input-placeholder": "Որոնել",
        "home-slider-see-more-link-text": "Դիտեք ավելին",
        "home-management-section-see-more-button": "Դիտեք ավելին",
        "home-distribution-section-see-more-button": "Դիտեք ավելին",
        "home-partners-section-title": "Գործընկերներ",
        "home-partners-section-see-more-button": "Դիտեք ավելին",
        "home-contacts-section-title": "Կապ",
        "home-contacts-section-input-name-label": "Անուն",
        "home-contacts-section-input-email-label": "Էլ հասցե",
        "home-contacts-section-input-message-label": "Հաղորդագրություն",
        "home-contacts-section-send-email-button-text": "Ուղարկել",
        "home-contacts-section-required-label": "Բոլոր դաշտերը պարտադիր են",
        "home-contacts-section-view-all-regions-button": "Տեսեք բոլոր մարզերը",
        "home-contacts-section-address-title": "Հասցե",
        "home-contacts-section-phone-title": "Հեռ.",
        "home-contacts-section-email-title": "Էլ հասցե",
        "contacts-page-title": "Կապ",
        "contacts-page-send-message-title": "Թողեք հաղորդագրություն",
        "contacts-page-input-name-label": "Անուն",
        "contacts-page-input-email-label": "Էլ. հասցե",
        "contacts-page-input-message-label": "Հաղորդագրություն",
        "contacts-page-send-email-button-text": "Ուղարկել",
        "contacts-page-required-label": "Բոլոր դաշտերը պարտադիր են",
        "vacancies-page-title": "Թափուր աշխատատեղեր",
        "vacancies-page-header-text": "Դիմեք այսօր և դարձեք մեզանից մեկը:",
        "about-page-title": "Մեր մասին",
        "partners-page-title": "Գործընկերներ",
        "partners-button-text": "Դիտեք ավելին",
        "developed-by-company": "By Studio One",
        "search-page-title": "Որոնել",
        "page-notFound-text": "Oops, Page not found-hy",
        "result-not-found": "Արդյունքը չի գտնվել"
    },
    "ru": {
        "default-title": "C&F Co.",
        "default-description": "C&F Co.",
        "menu-search-input-placeholder": "Поиск",
        "home-slider-see-more-link-text": "Посмотреть ещё",
        "home-management-section-see-more-button": "Посмотреть ещё",
        "home-distribution-section-see-more-button": "Посмотреть ещё",
        "home-partners-section-title": "Партнеры",
        "home-partners-section-see-more-button": "Посмотреть ещё",
        "home-contacts-section-title": "Контакты",
        "home-contacts-section-input-name-label": "Имя",
        "home-contacts-section-input-email-label": "Электронная почта",
        "home-contacts-section-input-message-label": "Текст сообщения",
        "home-contacts-section-send-email-button-text": "Отправить",
        "home-contacts-section-required-label": "Все поля обязательные для заполнения",
        "home-contacts-section-view-all-regions-button": "Просмотреть все регионы",
        "home-contacts-section-address-title": "Адрес",
        "home-contacts-section-phone-title": "Телефон",
        "home-contacts-section-email-title": "Эл. адрес",
        "contacts-page-title": "Контакты",
        "contacts-page-send-message-title": "Оставьте сообщение",
        "contacts-page-input-name-label": "Имя",
        "contacts-page-input-email-label": "Электронная почта",
        "contacts-page-input-message-label": "Текст сообщения",
        "contacts-page-send-email-button-text": "Отправить",
        "contacts-page-required-label": "Все поля обязательные для заполнения",
        "vacancies-page-title": "Вакансии",
        "vacancies-page-header-text": "Подайте заявку сегодня и станьте одним из нас!",
        "about-page-title": "О нас",
        "partners-page-title": "Партнеры",
        "partners-button-text": "Посмотреть ещё",
        "developed-by-company": "By Studio One",
        "search-page-title": "Поиск",
        "page-notFound-text": "Oops, Page not found",
        "result-not-found": "Результат не найден"
    },
    "en": {
        "default-title": "C&F Co.",
        "default-description": "C&F Co.",
        "menu-search-input-placeholder": "Search",
        "home-slider-see-more-link-text": "See more",
        "home-management-section-see-more-button": "See more",
        "home-distribution-section-see-more-button": "See more",
        "home-partners-section-title": "Partners",
        "home-partners-section-see-more-button": "See more",
        "home-contacts-section-title": "Contacts",
        "home-contacts-section-input-name-label": "Name",
        "home-contacts-section-input-email-label": "Email",
        "home-contacts-section-input-message-label": "Message",
        "home-contacts-section-send-email-button-text": "Send",
        "home-contacts-section-required-label": "Mandatory field",
        "home-contacts-section-view-all-regions-button": "See all Regions",
        "home-contacts-section-address-title": "Address",
        "home-contacts-section-phone-title": "Tel.",
        "home-contacts-section-email-title": "Email",
        "contacts-page-title": "Contact",
        "contacts-page-send-message-title": "Your message",
        "contacts-page-input-name-label": "Name",
        "contacts-page-input-email-label": "Email",
        "contacts-page-input-message-label": "Message",
        "contacts-page-send-email-button-text": "Send",
        "contacts-page-required-label": "Mandatory field",
        "vacancies-page-title": "Vacancy",
        "vacancies-page-header-text": "Join US!",
        "about-page-title": "About us",
        "partners-page-title": "Partners",
        "partners-button-text": "See more",
        "developed-by-company": "By Studio One",
        "search-page-title": "Search",
        "page-notFound-text": "Oops, Page not found-en",
        "result-not-found": "Result not found"
    }
}

// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../../assets/styles/pages/vacancies.scss";

//import Components
import {Collapse} from "antd";
import {GetVacancies, SetMetaData} from "../redux/actions";
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {generateMediaUrl} from "../utils/generateMediaUrl";
import {Link} from "react-router-dom";
import PageHeader from "../components/dataDisplay/PageHeader";
import {changeImageFormat} from "../utils/resizeImage";

const {Panel} = Collapse;

class Vacancies extends Component {
    async componentDidMount() {
        await this.props.GetVacancies();
        const {vacanciesPageData} = this.props;
        this.props.SetMetaData({
            title: vacanciesPageData?.metaTitle,
            metaDescription: vacanciesPageData?.metaDescription
        })
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage, vacanciesPageData} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            await this.props.GetVacancies();
            this.props.SetMetaData({
                title: vacanciesPageData?.metaTitle,
                metaDescription: vacanciesPageData?.metaDescription
            })
        }
    }


    render() {
        const {vacanciesPageData, translation} = this.props;
        const pageHeader = {
            title: translation && translation['vacancies-page-title']
        };
        return (
            <div className="vacancies-page-wrapper">
                <PageHeader pageHeader={pageHeader}/>
                <div className="vacancies-header">
                    {translation && translation['vacancies-page-header-text']}
                </div>
                <div className="vacancies-content">
                    <Collapse accordion
                              expandIconPosition={"right"}
                              expandIcon={(value) => {
                                  return <img className={value.isActive ? 'activeAddress' : ''}
                                              src={require('../../assets/images/contacts/open.svg')}
                                              alt="open"/>
                              }}
                    >
                        {vacanciesPageData?.areas?.map(item => {
                            return <Panel header={item.title} key={item.id}>
                                <div className="description"
                                     dangerouslySetInnerHTML={{__html: item.description}}/>
                            </Panel>
                        })}
                    </Collapse>
                </div>
                <section className='vacancies-section'>
                    <div className="section-img">
                        <img
                            src={changeImageFormat(generateMediaUrl(vacanciesPageData?.sections?.[0]?.mediaMain?.path))}
                            alt={vacanciesPageData?.sections?.[0]?.mediaMain?.altAttribute || ''}/>
                    </div>
                    <div className="section-content">
                        <h3 className="section-title">{vacanciesPageData?.sections?.[0]?.title}</h3>
                        <div
                            className="section-description" dangerouslySetInnerHTML={{__html: vacanciesPageData?.sections?.[0]?.description}}/>
                    </div>
                </section>
                <section className='vacancies-section'>
                    <div className="section-content">
                        <h3 className="section-title">{vacanciesPageData?.sections?.[1]?.title}</h3>
                        <div
                            className="section-description" dangerouslySetInnerHTML={{__html: vacanciesPageData?.sections?.[1]?.description}}/>
                    </div>
                    <div className="section-img">
                        <img
                            src={changeImageFormat(generateMediaUrl(vacanciesPageData?.sections?.[1]?.mediaMain?.path))}
                            alt={vacanciesPageData?.sections?.[2]?.mediaMain?.altAttribute || ''}/>
                    </div>
                </section>
            </div>
        );
    }
}

const mapDispatchToProps = {
    GetVacancies,
    SetMetaData
};
export default connect(mapStateToProps, mapDispatchToProps)(Vacancies)

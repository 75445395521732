import {authSelectors} from "./authSelectors";
import {utilSelectors} from "./utilSelectors";
import {userSelectors} from "./userSelectors";
import {roleSelectors} from "./roleSelectors";
import {partnerSelectors} from "./partnerSelectors";
import {textSelectors} from "./textSelectors";
import {languagesSelectors} from "./languageSelectors";
import {gallerySelectors} from "./gallerySelectors";
import {menuSelectors} from "./menuSelectors";
import {pageSelectors} from "./pageSelectors";
import {sliderSelectors} from "./sliderSelectors";
import {contactSelectors} from "./contactSelectors";
import {reviewSelectors} from "./reviewSelectors";
import {aboutSelectors} from "./aboutSelectors";

export default state => {
    return {
        ...authSelectors(state),
        ...userSelectors(state),
        ...roleSelectors(state),
        ...utilSelectors(state),
        ...partnerSelectors(state),
        ...textSelectors(state),
        ...languagesSelectors(state),
        ...gallerySelectors(state),
        ...menuSelectors(state),
        ...pageSelectors(state),
        ...sliderSelectors(state),
        ...contactSelectors(state),
        ...reviewSelectors(state),
        ...aboutSelectors(state),
    }
}

import {authConsts, aboutConsts} from "../constants";

export const initialState = {
    aboutData: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case aboutConsts.GET_ABOUT_DATA:
        case aboutConsts.UPDATE_ABOUT_DATA:
            return {
                ...state,
                aboutData: action.payload
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

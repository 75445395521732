import {authConsts, utilConsts} from "../constants";

const initialState = {
    leftSideMenuOpen: true,
    requestLoading: false,
    redirectedFirstTime:false,
    firstAccessedPage:'/admin/users',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case utilConsts.TOGGLE_LEFT_SIDE_MENU:
            return {
                ...state,
                leftSideMenuOpen: !state.leftSideMenuOpen
            };
        case utilConsts.START_LOADING:
            return {
                ...state,
                requestLoading: true
            };
        case utilConsts.END_LOADING:
            return {
                ...state,
                requestLoading: false
            };
            case utilConsts.SET_REDIRECTION_STATE:
            return {
                ...state,
                redirectedFirstTime: action.payload.state,
                firstAccessedPage: action.payload.url,
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

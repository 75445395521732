import {authConsts, reviewConsts} from "../constants";

export const initialState = {
    reviewList: {
        mediaMain: null,
        reviews: [],
        id: null,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case reviewConsts.GET_REVIEW_LIST:
            return {
                ...state,
                reviewList: action.payload
            };
        case reviewConsts.DELETE_REVIEW_BY_ID:
            return {
                ...state,
                reviewList: {
                    ...state.reviewList,
                    reviews: state.reviewList.reviews.filter(review => review.id !== action.payload)
                }
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

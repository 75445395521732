// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// Import utils
import {mapStateToProps} from "../../../admin/redux/mapStateToProps";
import {GetSliderWeb} from "../../redux/actions";
import {generateMediaUrl} from "../../utils/generateMediaUrl";

// Import components
import Slider from "react-slick";

// Import styles
import "../../../assets/styles/homePage/review-slider.scss";
import {changeImageFormat, resizeImage} from "../../utils/resizeImage";

class ReviewSlider extends Component {

    render() {
        let {reviewList} = this.props;
        const settingsImages = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
            <div className="review-slider" ref={this.props.refProp}>
                {<img
                    src={changeImageFormat(generateMediaUrl(reviewList?.mediaMain?.path))}
                    alt={reviewList?.mediaMain?.altAttribute || ""}
                />}
                <div className={"slider-caption"}>
                    <div className={'slider-wrapper'}>
                        {reviewList?.reviews && !!reviewList?.reviews.length &&
                        <img className={'review-quota'}
                             src={require(`../../../assets/images/homepage/quota.svg`)}
                             alt="icon"/>
                        }
                        <div className={'slider-box'}>
                            <Slider {...settingsImages}>
                                {reviewList?.reviews?.map((item, index) => {
                                    return <div key={index} className={"slider-item"}>
                                        <div className={'review-text'}>{item.text}</div>
                                        <div className={'review-author'}>{item.author}</div>
                                    </div>
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSlider);

//import packages
import React, {Component} from "react";
import {connect} from 'react-redux';

//import style
import "../../assets/styles/pages/contact.scss";

//import Components
import {Icon, Spin, Collapse} from "antd";
import isEmail from "validator/lib/isEmail";
import PageHeader from "../components/dataDisplay/PageHeader";
import {FormInput} from "../components/dataDisplay/UIElements";
import ContactMap from "../components/ContactMap";
import {AlertDialog} from "../components/dataDisplay/alertDialog";

//import utils
import {GetContactsWeb, SendContactsEmail, SetMetaData} from "../redux/actions";
import {history} from "../config/history";
import {closeAlertModal, openAlertModal} from "../../admin/utils/commonFunctions";
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {generateMediaUrl} from "../utils/generateMediaUrl";
import {recaptchaKey} from "../redux/api/apiKey";
import {changeImageFormat} from "../utils/resizeImage";

const {Panel} = Collapse;


class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contactData: {
                name: "",
                email: "",
                message: "",
            },
            errors: {
                name: false,
                email: false,
                message: false,
            },
            activeMarker: null,
            alertDialogOpen: false,
            alertMode: "",
            requestLoading: false
        };
        this.closeAlertModal = closeAlertModal.bind(this);
        this.openAlertModal = openAlertModal.bind(this);
        this.activeAddress = this.activeAddress.bind(this);
        this.getInputValues = this.getInputValues.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    componentDidMount() {
        const {contacts} = this.props;
        //console.log(contacts)
        this.props.SetMetaData({
            title: contacts?.metaTitle,
            metaDescription: contacts?.metaDescription
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage, contacts} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.SetMetaData({
                title: contacts?.metaTitle,
                metaDescription: contacts?.metaDescription
            })
        }
    }

    activeAddress(key) {
        this.setState({activeMarker: key})
    }

    getInputValues(e) {
        const {name, value} = e.target;

        this.setState({
            contactData: {
                ...this.state.contactData,
                [name]: value,
            },
        })
    }

    async sendEmail() {
        await this.setState({
            requestLoading: true
        });

        const {contactData} = this.state;

        const errors = {
            name: false,
            email: false,
            message: false,
        };
        let allFieldsIsValid = true;

        Object.keys(contactData).forEach((key) => {
            if (!contactData[key]) {
                errors[key] = true;
                allFieldsIsValid = false;
            }
            if (key === 'email' && !isEmail(contactData[key])) {
                errors[key] = true;
                allFieldsIsValid = false;
            }
        })
        if (allFieldsIsValid) {
            window?.grecaptcha && window.grecaptcha.execute(recaptchaKey, {action: 'send_email'}).then( (token) =>{
                const reqData = {
                    name: contactData.name,
                    email: contactData.email,
                    message: contactData.message,
                    recaptchaToken:token
                };

                this.props.SendContactsEmail(reqData).then(() => {
                    //this.openAlertModal('success');
                    this.setState({
                        contactData: {
                            name: "",
                            email: "",
                            message: "",
                        },
                        errors: {
                            name: false,
                            email: false,
                            message: false,
                        },
                    })
                }).catch(() => {
                    //this.openAlertModal('warning')
                }).finally(() => {
                    this.setState({
                        requestLoading: false
                    })
                })
            })
        } else {
            this.setState({
                errors,
                requestLoading: false
            })
            //this.openAlertModal('warning')
        }
    }

    render() {
        const spinIcon = <Icon type="loading" style={{fontSize: 16, color: "#45A1EF"}} spin/>;
        const {contactData, requestLoading, errors, alertDialogOpen, alertMode, recaptchaVerified} = this.state;
        const {contacts, translation} = this.props;
        const pageHeader = {
            title: translation && translation['contacts-page-title'],
        };
        return <div className="contact-page">
            <PageHeader pageHeader={pageHeader}/>
            <div className="contact-top-wrapper">
                <div className="container-contacts">
                    <Collapse accordion
                              expandIconPosition={"right"}
                              expandIcon={(value) => {
                                  return <img className={value.isActive ? 'activeAddress' : ''}
                                              src={require('../../assets/images/contacts/open.svg')}
                                              alt=""/>
                              }}
                              onChange={this.activeAddress}>{
                        contacts?.locations?.map((item) => {
                            return <Panel header={item.name} key={item.id}>
                                <div className="info-wrapper">
                                    <div className="info-content">
                                        <div
                                            className="title">{translation && translation['home-contacts-section-address-title']}</div>
                                        <div>{item.address}</div>
                                    </div>
                                    <div className="info-content">
                                        <div
                                            className="title">{translation && translation['home-contacts-section-phone-title']}</div>
                                        <div>{item.phone}</div>
                                    </div>
                                    <div className="info-content">
                                        <div
                                            className="title">{translation && translation['home-contacts-section-email-title']}</div>
                                        <div>{item.email}</div>
                                    </div>
                                </div>
                            </Panel>
                        })
                    }
                    </Collapse>
                </div>
                <div className="map-wrapper">
                    <ContactMap locations={contacts.locations}
                                activeMarker={this.state.activeMarker}/>
                </div>
                <div className="bg-containers">
                    <div className="light-blue"/>
                    <div className="dark-blue"/>
                </div>
            </div>
            <div className="contact-wrapper-bottom">
                <img src={changeImageFormat(generateMediaUrl(contacts?.mediaMain?.path))}
                     alt={contacts?.mediaMain?.altAttribute || ""}/>
                <div className="send-message">
                    <h3 className="send-message-title">{translation && translation['contacts-page-send-message-title']}</h3>
                    <div className="form-wrapper">
                        <div className="left">
                            <div
                                className={errors.name ? 'input-error input-content' : 'input-content'}>
                                <FormInput
                                    type="text"
                                    label={translation && translation['contacts-page-input-name-label']}
                                    name="name"
                                    value={contactData.name || ""}
                                    error={errors.name}
                                    cb={this.getInputValues}
                                />
                            </div>
                            <div
                                className={errors.email ? 'input-error input-content' : 'input-content'}>
                                <FormInput
                                    type="email"
                                    label={translation && translation['contacts-page-input-email-label']}
                                    name="email"
                                    value={contactData.email || ""}
                                    error={errors.email}
                                    cb={this.getInputValues}
                                />
                            </div>
                        </div>
                        <div className="right">
                            <div
                                className={errors.message ? 'input-error input-content input-textarea' : 'input-content input-textarea'}>
                                <FormInput
                                    type="textarea"
                                    label={translation && translation['contacts-page-input-message-label']}
                                    name="message"
                                    value={contactData.message || ""}
                                    error={errors.message}
                                    cb={this.getInputValues}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="confirm-message-wrapper">
                        <div className="send-button-content">
                            <div className="button-wrapper">
                                <button className="link-button" onClick={this.sendEmail}>
                                    <div className="flex-row">
                                        {translation && translation['contacts-page-send-email-button-text']}
                                        {requestLoading && <Spin indicator={spinIcon}/>}
                                    </div>
                                </button>
                                <div className="error-text">
                                    {translation && translation['contacts-page-required-label']}
                                    <i>*</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AlertDialog title="Warning!"
                         message="Please try again..."
                         buttons={["Close"]}
                         isOpen={alertDialogOpen && alertMode === "warning"}
                         closeModal={this.closeAlertModal}/>
            <AlertDialog title="Success!"
                         message="Your email successfully send"
                         buttons={["Close"]}
                         isOpen={alertDialogOpen && alertMode === "success"}
                         closeModal={() => history.push('/')}/>
        </div>
    }
}

const mapDispatchToProps = {
    SendContactsEmail,
    GetContactsWeb,
    SetMetaData
};
export default connect(mapStateToProps, mapDispatchToProps)(Contact)
